import Layout from "components/layout";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {lazy, Suspense} from "react";
import ContentLoader from "components/contentLoader";

const HomePage = lazy(() => import("pages/home"));
const QuemSomosPage = lazy(() => import("pages/quem-somos"));
const ValoresMissaoVisaoPage = lazy(() => import("pages/valores-missao-visao"));
const ContatoPage = lazy(() => import("pages/contato"));
const ServicosPage = lazy(() => import("pages/servicos"));

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: '/',
                element: <HomePage/>,
            },
            {
                path: '/quem-somos',
                element: <QuemSomosPage />,
            },
            {
                path: '/valores-missao-visao',
                element: <ValoresMissaoVisaoPage />,
            },
            {
                path: '/servicos',
                element: <ServicosPage />,
            },
            {
                path: '/contato',
                element: <ContatoPage />,
            }
        ]

    }
]);

const Rotas = () => {
    return (
        <>
            <Suspense fallback={<ContentLoader />}>
                <RouterProvider router={router}/>
            </Suspense>
        </>
    )
}

export default Rotas