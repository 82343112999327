import React, {Fragment, useState} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Logo from "components/logo";
import {NavLink, useNavigate} from "react-router-dom";
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Theme,
    useMediaQuery
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {grey} from "@mui/material/colors";

const pages = [
    {nome: 'Home',link: "/"}, 
    {nome: 'Quem  Somos',link: "/quem-somos"}, 
    {nome: 'Missão, visão e valores',link: "/valores-missao-visao"}, 
    {nome: 'Nossos Serviços',link: "/servicos"},
    {nome: 'Contato',link: "/contato"},
];

const TopBar = () => {
    const navigate =  useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const downSm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));


    return (
        <AppBar position="fixed" sx={{height: 100, background: "rgba(0, 0, 0, .8)"}}>
            <Container maxWidth="md" sx={{height: "100%"}}>
                <Toolbar disableGutters sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%"
                }}>
                    <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: {xs: "space-between",md: 'space-around'}}}>
                        <NavLink to={"/"}>
                            <Logo width={75} height={"auto"} />
                        </NavLink>
                        {!downSm && pages.map((page, i) => (
                            <Button
                                key={i}
                                sx={{color: 'white', display: 'block', textTransform: 'capitalize', mr:1}}
                                onClick={() => navigate(page.link)}
                            >
                                {page.nome}
                            </Button>
                        ))}
                        {downSm && (
                            <>
                                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => setOpenDrawer(true)}>
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    anchor={'left'}
                                    open={openDrawer}
                                    onClose={() => setOpenDrawer(false)}
                                    variant={'temporary'}
                                >
                                    <List>
                                        <>
                                            <ListItem disablePadding sx={{color: "white"}}>
                                                <ListItemButton onClick={() => setOpenDrawer(false)} sx={{display: "flex", justifyContent: "end"}}>
                                                        <CloseIcon />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{color: "white"}}>
                                                <ListItemButton onClick={() => {
                                                    navigate("/")
                                                    setOpenDrawer(false)
                                                }} sx={{display: "flex", justifyContent: "center"}}>
                                                    <Logo width={150} height={"auto"} />
                                                </ListItemButton>
                                            </ListItem>
                                            {pages.map((page, i) => (
                                                <Fragment key={i}>
                                                    <ListItem disablePadding sx={{color: "white"}}>
                                                        <ListItemButton onClick={() => {
                                                            navigate(page.link);
                                                            setOpenDrawer(false);
                                                        }}>
                                                            <ListItemText primary={page.nome} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider color={grey[900]} />
                                                </Fragment>
                                            ))}
                                        </>
                                    </List>

                                </Drawer>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopBar;